import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, Table, Row, InfoIcon, WarningIcon } from './components';
export const newClientParams = [{
  param: 'Client id',
  value: 'You can use any name you want as long as it’s unique. It will be used to identify your client when you authenticate'
}, {
  param: 'Display name',
  value: 'This is how users will see your application'
}, {
  param: 'Client platform',
  value: 'Default or iOS application'
}, {
  param: 'OAuth Support',
  value: 'Select between autonomous or user pin as the form of authentication you will be using.'
}, {
  param: 'Company',
  value: 'Details about your company'
}, {
  param: 'Info URL',
  value: 'An info url'
}, {
  param: 'Short description',
  value: 'A short description of the client'
}, {
  param: 'Description',
  value: 'A description of the client'
}, {
  param: 'Company logo',
  subtitle: 'optional',
  value: 'An image input to upload your company logo'
}, {
  param: 'List in marketplace',
  value: "Check this option if it's a production / retail application, otherwise leave it unchecked"
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  newClientParams,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p>{`Find below a detailed walkthrough that can guide you through the required steps before you can start using the SmartVault API and some suggestions on what you can do while familiarizing with it.`}</p>
    <br />
    <h2 {...{
      "id": "1-register-for-a-smartvault-developer-account"
    }}>{`1. Register for a SmartVault developer account`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`To make use of the endpoints stated in this API, you'll need to `}<strong parentName="p">{`register`}</strong>{` a Smartvault developer account first of all.
To do this, go to this `}<a parentName="p" {...{
        "href": "https://my.smartvault.com/secure/signup.aspx?a=0&s=1"
      }}>{`link`}</a></p>
    <InfoIcon mdxType="InfoIcon" /> Once registered, you'll receive an <strong>activation email</strong> that's needed to be accepted to follow
on.
    <br />
    <br />
When you click the link received on the email used during registration, you should be able to <strong>
	log in
    </strong> using the newly created account credentials.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> If there are more than one account, select the <strong>developer</strong> one and you should see something
like the following image:
    <br />
    <br />
    <Image src={'/1_default_profile_view.png'} alt="Logged in user view" title="Logged in user view" mdxType="Image" />
    <br />
    <p>{`This means your new developer account is set up and good to go!`}</p>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "2-adding-a-new-client"
    }}>{`2. Adding a new client`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Go to the `}<strong parentName="p">{`Developer Clients`}</strong>{` tab and press the `}<strong parentName="p">{`Create New Client`}</strong>{` button.`}</p>
    <Image src={'/2_add_new_client.png'} alt="Create new client" title="Create new client" mdxType="Image" />

    <br />
    <br />
    <Row mdxType="Row">
	<Image src={'/3_new_client_popup.png'} heading={'A popup will show prompting you to fill some fields:'} alt="Add new client popup" title="Add new client popup" mdxType="Image" />
	<Table data="info" title="Client details parameters" rows={newClientParams} mdxType="Table" />
    </Row>
    <br />
    <div>
	The data used here will be used for everything related to authentication.
	<br />
	<br />
	<InfoIcon mdxType="InfoIcon" /> If you select <strong>Autonomous</strong> as the OAuth support option, you'll need to generate a pair of
	public / private keys. Find more information about this <a href="/api/getting-started#generating-a-key-for-autonomous-auth">
		in the section below
	</a>.
    </div>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "3-authentication-steps"
    }}>{`3. Authentication steps`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <h4 {...{
      "id": "31-requesting-a-nonce"
    }}>{`3.1 Requesting a nonce`}</h4>
    <p>{`First of all, we need to request a nonce from SmartVault for this particular new developer client just created.`}</p>
    <InfoIcon mdxType="InfoIcon" /> Find more information about requesting a nonce <a target="_blank" href="/api/authorization/autonomous#request-a-nonce">
	here
    </a>.
    <br />
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "32-requesting-a-client-token"
    }}>{`3.2 Requesting a client token`}</h4>
    <p>{`To retrieve the client token, we need to use the private key from the public / private key pair generated while creating the new client on the `}<a parentName="p" {...{
        "href": "/api/getting-started#2-adding-a-new-client"
      }}>{`previous section`}</a>{`.`}</p>
    <InfoIcon mdxType="InfoIcon" /> Find more information about retrieving a client token and check some code snippets that can help build a
helper to retrieve your client token in your integration <a target="_blank" href="/api/authorization/autonomous#request-a-client-token">
	here
    </a>.
    <br />
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "33-get-authorization-url-for-delegated-user"
    }}>{`3.3 Get authorization URL for delegated user`}</h4>
    <p>{`You need to do this step to authorize every new user that will use the API. After performing this request, you will get a url for the user to authorize this client id integration to use the SmartVault API on behalf of them.`}</p>
    <InfoIcon mdxType="InfoIcon" /> Find more information about retrieving this URL <a target="_blank" href="/api/user-account/stat-user#authorized">
	here
    </a>.
    <br />
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "34-request-delegation-token-for-user"
    }}>{`3.4 Request delegation token for user`}</h4>
    <p>{`This will create a delegation token that will be used with the authorized user email in pretty much every following API data request as the Authorization header.`}<br /></p>
    <InfoIcon mdxType="InfoIcon" /> Remember that you need to use the SmartVault account email for this request, not the developer account
email.
    <br />
    <InfoIcon mdxType="InfoIcon" /> Find more information about retrieving the delegation token <a target="_blank" href="/api/authorization/autonomous#request-a-delegation-token">
	here
    </a>.
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "4-what-now"
    }}>{`4. What now?`}</h2>
    <p>{`Now that you managed to get your delegation token, you can use it to perform every possible data request in the SmartVault API.`}<br /><br /></p>
    <WarningIcon mdxType="WarningIcon" />
Take into account that, from now on, you need to specify the basic access authorization header value for the data request
like:
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`userEmail + ':' + delegationToken;
`}</code></pre>
    <p>{`Also, remember that for `}<a parentName="p" {...{
        "href": "/api/authorization/autonomous#request-a-delegation-token"
      }}>{`requesting a new delegation token`}</a>{` or `}<a parentName="p" {...{
        "href": "/api/user-account/stat-user"
      }}>{`getting user account information`}</a>{` you will need to set it up like the following instead:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`clientId + ':' + clientToken;
`}</code></pre>
    <p>{`Knowing this, we can suggest some things to do to familiarize with the SmartVault API like exploring its structure, creating new folder, uploading files or creating clients and / or engagements.`}</p>
    <br />
    <br />
    <h3 {...{
      "id": "41-getting-to-know-the-account-structure"
    }}>{`4.1 Getting to know the account structure`}</h3>
    <div>
	If you are working with an structured account (check <a target="_blank" href="/api#retrieving-plan-type">here</a> if you want to know about your account type) you will be able to{' '}
	<strong>explore the account structure (vault, folders and files)</strong> by performing a simple{' '}
	<span style={{
        "color": "#3c76e9"
      }}>GET</span> request to
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/pth?children=1&eprop=1&vprop=1
`}</code></pre>
    <InfoIcon mdxType="InfoIcon" /> Check <a target="_blank" href="/api#requests-query-parameters">
	here
    </a> for more information about the query parameters used that make the request more specific and / or show extra
parameters.
    <br />
    <br />
    <br />
    <p>{`Knowing that the structure follows this hierarchy:`}</p>
    <code>Account -> Clients -> Containers -> Vaults -> Folders -> Files</code>
    <p>{`and, for the sake of the example, let's suppose the Account name is "SmartVault", we would be able to to a request like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/pth/SmartVault?children=1&eprop=1&vprop=1
`}</code></pre>
    <p>{`This would yield us with all the Vaults present in the account.`}</p>
    <br />
    <br />
    <p>{`You can explore the whole nodes structure using the same request structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/pth/SmartVault/Folder1/Folder2/Document.pdf?children=1&eprop=1&vprop=1 // This example request would return a file called "Document.pdf" with FileNodeType
`}</code></pre>
    <InfoIcon mdxType="InfoIcon" /> Using the "children" query parameter here would be "mandatory" as it helps knowing the children of the node
you are currently checking.
    <br />
    <InfoIcon mdxType="InfoIcon" /> The request response will tell you which type of node you are retrieving in the field "nodeType"
(ContainerNodeType, FolderNodeType, FileNodeType).
    <br />
    <InfoIcon mdxType="InfoIcon" /> Check the <a target="_blank" href="/api/files">
	files
    </a> and <a target="_blank" href="/api/folders">
	folders
    </a> documentation for more specific information about this request and its CRUD operations (creating new folders,
uploading new files, etc.).
    <br />
    <br />
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "42-add-new-entities-to-your-account"
    }}>{`4.2 Add new entities to your account`}</h3>
    <p>{`In the SmartVault infrastructure, employees, clients and engagements are considered as "entities".`}<br />{`
We suggest you explore the `}<a parentName="p" {...{
        "href": "/api/entities/introduction"
      }}>{`Entities`}</a>{` section to get to know more about what interactions each entity have and learn more about them.`}<br /></p>
    <p>{`Some examples of what you can do using the specific entity documentation would be creating a new employee for an account or modifying an engagement.`}</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "generating-a-key-for-autonomous-auth"
    }}>{`Generating a key for autonomous auth`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`If you select the `}<strong parentName="p">{`autonomous`}</strong>{` option in the `}<strong parentName="p">{`OAuth Support`}</strong>{` dropdown while creating a new client, you'll be asked to fill a "Public key" textarea for what you'll need a RSA public / private key pair with a length of at least `}<strong parentName="p">{`1024`}</strong>{` bits.
To generate these keys, you can download and run `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://sdk.smartvault.com/wp-content/uploads/2014/12/KeyGenerator.zip"
        }}>{`KeyGenerator.exe`}</a></strong>{`, which will provide you with a `}<em parentName="p">{`private-key.xml`}</em>{` and a `}<em parentName="p">{`public-key.xml`}</em>{`, or you can use `}<strong parentName="p">{`OpenSSL`}</strong>{` to generate these keys by running the following two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`openssl genrsa -out rsaprivkey.pem 2048
openssl rsa -in rsaprivkey.pem -pubout -outform PEM -out rsapubkey.pem
`}</code></pre>
    <p>{`This will give you a public key in `}<strong parentName="p">{`rsapubkey.pem`}</strong>{` and a private key in `}<strong parentName="p">{`rsaprivkey.pem`}</strong>{`. You need to copy `}<strong parentName="p">{`the whole content of the public key, including the "header" and "footer" of it`}</strong>{` and paste that into the textarea.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`-----BEGIN PUBLIC KEY----- <- Don't ignore this
// YOUR KEY
-----END PUBLIC KEY----- <- Don't ignore this neither
`}</code></pre>
    <p>{`You'll need the private one later on when requesting a client token.`}</p>
    <br />
    <WarningIcon mdxType="WarningIcon" />
It is your responsibility to keep the private key secure, since this is what gives you access to your users’ data.
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      